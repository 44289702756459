import * as React from "react";

interface Props {
  title: string
  children: string | JSX.Element | (string | JSX.Element)[]
}

const FAQItem = ({title, children}: Props) => {
  return <>
    <h2 className={"plain-h2"}>{title}</h2>
    {children}
  </>
}

export default FAQItem;
