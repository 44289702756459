import * as React from "react";
import LayoutPlain from "../../components/LayoutPlain";
import FAQItem from "../../components/FAQItem";

const FAQTRPage = () => {
  return <LayoutPlain>
    <div className={"faq"} style={{padding: "1em"}}>
      <FAQItem title={"‘paramla’ nedir? Nasıl kullanılır?"}>
        <p>paramla’nın misyonu, tasarrufu teşvik ederek ve finansal okuryazarlığın gelişimine katkıda bulunarak, ‘finansal özgürlük’ kazanımını kolaylaştırmaktır.</p>

        <div className={"div-inner"}>
          paramla ile,
          <div className={"div-inner"}>
            <ul className={"ul-tick"}>
              <li>TL (Kasa | Vadesiz Mevduat | Vadeli Mevduat | BES | Diğer)</li>
              <li>BIST (15 dk gecikmeli)</li>
              <li>Emtia</li>
              <li>Döviz</li>
              <li>Fon (Menkul Kıymet | Emeklilik | BYF)</li>
              <li>Eurobond (15 dk gecikmeli)</li>
              <li>Kripto</li>
              <li>ABD Borsaları</li>
            </ul>
            <p>
              olmak üzere, 8 kategoride toplam <u>25 bin varlık</u> arasından seçerek, basitçe miktarlarını (örneğin ₺100.000, 1.000 adet Akbank Hissesi, 5 adet Cumhuriyet Altını,
              €500,
              100.000 adet AGESA Emeklilik Fonu, $3.000 Eurobond, 1 adet BTC, 50 adet Apple Hissesi vb.) ve birim maliyetlerini girmek suretiyle, varlıklarınızın
            </p>

            <ul className={"ul-tick"}>
              <li>değerlerini</li>
              <li>yüzdesel dağılımlarını</li>
              <li>değişimlerini</li>
              <li>toplam ve ortalama maliyetlerini</li>
              <li>kar/zarar tutar ve oranlarını</li>
              <li>kar/zarar tutarlarının değişimlerini</li>
            </ul>
            <p>
              25 bin varlığın fiyatları, değişimleri ve <u>‘En Çok Kazandıranlar</u>’ıyla,
            </p>

            <ul className={"ul-tick"}>
              <li>tek ekranda</li>
              <li>80 para biriminde</li>
              <li>okuyucu dostu grafiklerle</li>
              <li><u>20</u> adede kadar <u>portföy</u> oluşturup dilediğiniz gibi isimlendirerek</li>
              <li><u>10 cihaza</u> kadar senkronize edip portföylerinizi <u>paylaşarak</u> / <u>birlikte yöneterek</u></li>
              <li>önemli finansal haberleri, bildirimlerimiz üzerinden kolaylıkla takip ederek</li>
              <li>tek dokunuşla, ister Türkçe ister İngilizce olarak</li>
              <li>Yüz/Parmak Tanıma güvenlik desteğiyle</li>
              <li><u>her daim ‘anonim’ kalarak</u></li>
              <li><u>30 gün ücretsiz deneyerek</u></li>
            </ul>

            kolaylıkla takip edebilirsiniz.
          </div>
        </div>
        <div className={"div-inner"}>
          paramla’da,
          <div className={"div-inner"}>
            <ul className={"ul-bullet"}>
              <li>kayıt (hesap oluşturma) | giriş | şifre belirleme</li>
              <li>herhangi bir kişisel veri (ad, soyad, e-posta adresi vb.) paylaşımı</li>
              <li>her ne suretle olursa olsun, herhangi bir finansal varlığın saklama hizmeti, al/sat işlemi, yahut parasal bir hareket</li>
              <li>her ne suretle olursa olsun, yatırım tavsiyesi veya danışmanlığı</li>
            </ul>
          </div>
          <u>söz konusu değildir.</u>
        </div>
        <p>Keyifli kullanımlar ve bol kazançlı birikimler dileriz!</p>
      </FAQItem>

      <FAQItem title={"Ekranlardaki rakamların manaları nedir?"}>
        <div className={"div-inner"}>
          <u>Portföy (‘p’) Ekranı</u>:
          <div className={"div-inner"}>
            <ul>
              <li>Toplam Portföy Değeri’nizin altında gördüğünüz rakam ve yüzde, <u>Toplam Portföy Değeri’nizin bir önceki gün sonuna kıyasla değişim tutarı ve yüzdesi</u> (yeşilse
                artış,
                kırmızıysa azalış)
              </li>

              <li>Alt tarafta, kategori toplamları ve varlık tutarlarınızın altında (sağa yaslı olarak) gördüğünüz rakam ve yüzdeler de yine <u>Portföy Değerleri’nizin bir önceki
                gün
                sonuna kıyasla değişim tutar ve yüzdeleri</u> (yeşilse artış, kırmızıysa azalış)
              </li>

              <li>Alt tarafta, varlık isimlerinin altında (sola yaslı olarak) gördüğünüz rakam ve yüzdeler ise, varlıklarınızın <u>Birim Fiyat’ları</u> (BIST ve Eurobond için 15 dk
                gecikmeli) ve bu <u>Birim Fiyat’ların bir önceki gün sonuna kıyasla değişim tutar ve yüzdeleri</u> (yeşilse artış, kırmızıysa azalış)
              </li>
            </ul>
          </div>
          <u>Kar/Zarar Ekranı</u>:
          <div className={"div-inner"}>
            <ul>
              <li>Toplam Kar/Zarar (K/Z) tutarınızın altında gördüğünüz rakam ve yüzde, <u>Toplam K/Z tutarınızın bir önceki gün sonuna kıyasla değişim tutarı ve
                yüzdesi</u> (yeşilse
                artış,
                kırmızıysa azalış)
              </li>

              <li>Alt tarafta, kategori toplamları ve varlık tutarlarınızın altında (sağa yaslı olarak) gördüğünüz rakam ve yüzdeler de yine <u>K/Z tutarlarınızın bir önceki gün
                sonuna
                kıyasla
                değişim tutar ve yüzdeleri</u> (yeşilse artış, kırmızıysa azalış)
              </li>

              <li>Toplam K/Z tutarınızın sağında gördüğünüz yüzde, <u>‘Ağırlıklı Ortalama Maliyet’inize</u> (AOM) (miktarlarınızı düzenlerken peyderpey girmiş olduğunuz Birim
                Maliyet
                tutarları
                üzerinden, tarafımızca otomatik olarak hesaplanır / güncellenir) kıyasla <u>Toplam K/Z Oranı’nız</u> (yeşilse kar, kırmızıysa zarar)
              </li>

              <li>Alt tarafta, kategori ve varlık isimlerinin altında (sola yaslı olarak) gördüğünüz yüzdeler de yine <u>AOM’lerinize</u> kıyasla <u>K/Z Oranları’nız</u> (yeşilse
                kar, kırmızıysa
                zarar)
              </li>
            </ul>
          </div>
        </div>
      </FAQItem>

      <FAQItem title={"Varlık değerlerimin tarihsel değişimlerini nasıl görebilirim?"}>
        <p>Portföy (‘p’) ve Kar/Zarar ekranlarınızdan grafik ekranlarınıza geçerek (toplam Portföy Değeri / Kar/Zarar tutarınızın altındaki varyans tutar ve oranının sağında yer
          alan
          yukarı yönlü ok simgesine dokunarak) Portföyler Toplamı’nız, herhangi bir portföyünüz, dilediğiniz varlık kategorisi yahut tekil varlık için, dilediğiniz periyotta ve
          dilediğiniz para birimi cinsinden tarihsel değişimlerinizi (paramla’ya giriş yaptığınız ilk günden itibaren olmak kaydıyla) hem tutarsal hem de yüzdesel olarak
          inceleyebilirsiniz.</p>
      </FAQItem>

      <FAQItem title={"Miktarlarımın tarihsel değişimlerini nasıl görebilirim?"}>
        <p>Miktar girişi yaptığınız ekrandaki Para Birimi butonunun üzerine dokunduğunuzda açılacak listeden ‘Miktar’ seçmek suretiyle, varlık miktarlarınızın da tarihsel
          değişimini
          inceleyebilirsiniz.</p>
      </FAQItem>

      <FAQItem title={"Miktarlarımın kategori yahut toplam içindeki yüzdesel paylarını nasıl görebilirim?"}>
        <p>Portföy (‘p’) ekranınızda, Pasta Grafiği’nizin ortasındaki […] butonundan ‘Varlıkları Listele’yi seçerseniz, tüm varlıklarınızın miktar ve toplam içindeki yüzdesel
          paylarını;
          örneğin, önce ‘Hisse Senedi’ dilimine dokunup akabinde […] butonundan ‘Varlıkları Listele’yi seçerseniz de sadece ‘Hisse Senedi’ varlıklarınızın miktar ve ‘Hisse Senedi’
          kategorisi içindeki yüzdesel paylarını tek ekranda, topluca ve gayet ferah / okunaklı biçimde görebilirsiniz.</p>
      </FAQItem>

      <FAQItem title={"Varlıklarımı yahut grafiklerimi nasıl sıralatabilirim?"}>
        <p>Portföy (‘p’) & Kar/Zarar (K/Z) ekranlarınızda, sağ üstte, para biriminin sağındaki [↑↓] butonuna dokunarak, varlıklarınızı, Pasta Grafiği’nizi ve Liste Grafiği’nizi</p>

        <ol type={"a"}>
          <li>Alfabetik</li>
          <li>Artan oran (sadece K/Z ekranında)</li>
          <li>Artan tutar</li>
          <li>Azalan oran (sadece K/Z ekranında)</li>
          <li>Azalan tutar</li>
          <li>En son eklenen</li>
        </ol>

        kriterlerine göre sıralatabilirsiniz.

        <p style={{fontStyle: "italic"}}>
          <u>Not</u>: Ayrıca, Varlık Liste (Birim Fiyat) ekranlarınızda, sağ üstteki yıldız ikonuna dokunarak, favori varlıklarınızı sıralamada <u>kalıcı biçimde</u> yukarı
          taşıyabilirsiniz; ekranlarınız daha hızlı açılıyor olacaktır.
        </p>
      </FAQItem>

      <FAQItem title={"Portföylerimi nasıl sıralatabilirim?"}>
        Portföy (‘p’) & Kar/Zarar (K/Z) ekranlarınızda portföy isminizin üzerine dokunduğunuzda açılacak modaldaki [↑↓] butonuna dokunarak, portföylerinizi

        <ol type={"a"}>
          <li>Alfabetik</li>
          <li>Artan oran (sadece K/Z ekranında)</li>
          <li>Artan tutar</li>
          <li>Azalan oran (sadece K/Z ekranında)</li>
          <li>Azalan tutar</li>
          <li>En son eklenen</li>
        </ol>

        kriterlerine göre sıralatabilirsiniz.

        <p style={{fontStyle: "italic"}}>
          <u>Not</u>: Ayrıca, Varlık Liste (Birim Fiyat) ekranlarınızda, sağ üstteki yıldız ikonuna dokunarak, favori varlıklarınızı sıralamada <u>kalıcı biçimde</u> yukarı
          taşıyabilirsiniz;
          ekranlarınız daha hızlı açılıyor olacaktır.
        </p>
      </FAQItem>

      <FAQItem title={"Gösterilen Birim Fiyat’lar hangi kaynakların hangi fiyatlarıdır?"}>
        <ul>
          <li>Gösterdiğimiz Birim Fiyat’lar <u>‘Alış’ yahut ‘Satış’ değil, ‘Spot Piyasa Son İşlem’ (Alıcı ile Satıcı’nın karşılıklı rıza ile işlem yaptıkları)</u> fiyatlardır.</li>

          <li>‘BIST’ (15 dk gecikmeli), ‘Emtia’, ‘Döviz’ ve ‘Eurobond’ (15 dk gecikmeli) Birim Fiyatları’nı ‘ForInvest’ten (eski adıyla ‘Foreks’); ‘Kripto’ Birim Fiyatları’nı
            ‘CoinMarketCap’ten; ‘ABD’ Birim Fiyatları’nı ‘Polygon’dan dinlemekte ve göstermekteyiz.
          </li>
        </ul>
      </FAQItem>

      <FAQItem title={"Emtia fiyatları hangi kaynakların hangi fiyatlarıdır? Neden bazıları geç güncelleniyor?"}>

        <ul>
          <li>Gösterdiğimiz Birim Fiyat’lar <u>‘Alış’ yahut ‘Satış’ değil, ‘Spot Piyasa Son İşlem’ (Alıcı ile Satıcı’nın karşılıklı rıza ile işlem yaptıkları)</u> fiyatlardır.</li>

          <li>‘Emtia’ fiyatlarını ForInvest’ten (eski adiyla Foreks) dinliyor ve isimlerinde de açık ettiğimiz üzere, hem ‘Kapalı Çarşı (Serbest Piyasa)’ hem de ‘Spot Piyasa’
            fiyatlarını
            gösteriyoruz. Kapalı Çarşı (KÇ) fiyatları, KÇ ile eşzamanlı olarak, günde bir kaç kez güncellenir (güncellemeler bizzat KÇ’de yer alan bir ForInvest görevlisi
            tarafından
            manuel olarak yapıldığından, ilki saat 12:00’ye doğru olmaktadır); Spot Piyasa fiyatları ise anlık olarak ve sürekli güncellenir. ‘Emtia’ kategorimize girerek,
            miktarlarınızı
            dilediğiniz üzerinden düzenleyebilirsiniz.
          </li>
        </ul>
      </FAQItem>

      <FAQItem title={"BIST ve Eurobond verileri neden canlı değil?"}>

        <p>BIST ve Eurobond fiyatlarımız, yasal metinlerimiz ve ilgili Varlık Liste (Birim Fiyat) ekranlarınızda açık ettiğimiz üzere, 15 dk gecikmelidir (abonelik olsa da olmasa
          da).</p>


        <p>‘Anonimlik’ ilkemiz gereği herhangi bir kişisel verinizi (ad, soyad, e-posta adresi vb.) sorup saklamadığımızdan, yürürlükteki mevzuat tahtında canlı veri sunmamız
          mümkün
          olmamaktadır: canlı veri sunulabilmesi için, ay sonlarında BIST’e detaylı erişim (kim, hangi saatte, hangi canlı verilere vb.) raporlanması gerekmektedir.</p>

      </FAQItem>

      <FAQItem title={"Bedelsiz yahut bedelli olarak edindiğim hisselerimi nasıl eklemeliyim?"}>
        <ul>
          <li>Bölünme yahut bedelsiz sermaye artırımı suretiyle bedelsiz olarak edindiğiniz hisseleri, basitçe ‘Birim Maliyet’ alanına <u>bizzat 0 (sıfır) yazarak (boş bırakarak
            değil)</u> adetlerinize eklemeniz yeterlidir; Birim Fiyat güncellemeleri tarafımızca otomatik olarak yapılmaktadır.
          </li>

          <li>Bedelli olarak edindiğiniz hisseleri ise, sadece ilgili kısma dair ‘Birim Maliyet’ tutarı (yeni edindiğiniz 1 adet hisse için ödemiş olduğunuz edinme maliyeti) ile
            miktarınıza ilave edebilirsiniz.
          </li>
        </ul>
      </FAQItem>

      <FAQItem title={"BES varlıklarımı nasıl ekleyebilirim?"}>
        <p>
          Herkes BES fonlarının kırılımına / detayına / değişimine (belli tercihleri doğrultusunda, fonları otomatik revize edilenler var) hakim değil maalesef; o nedenle, ‘TL’
          kategorisinin alt kırılımlarından biri olarak sunmayı tercih ettik. Bununla birlikte, siz miktarsal (adetsel) kırılımlarına hakimseniz, basitçe ‘BES’ isimli ayrı bir
          portföy oluşturup ‘Fon’ kategorisinden miktarlarınızı ekleyebilirsiniz.
        </p>
      </FAQItem>

      <FAQItem title={"Gayrimenkul, araç, telefon, saat, mücevher vb varlıklarımı nasıl ekleyebilirim?"}>
        <p>
          ‘TL’ kategorisi altında yer alan ‘Diğer’ alt kategorilerinin isimlerini ‘GAYRİMENKUL’, ‘ARAÇ’, ‘TELEFON’, ‘SAAT’, ‘MÜCEVHER’ vb şeklinde dilediğiniz gibi isimlendirerek
          bu tür varlıklarınızın değerlerini TL cinsinden ekleyebilir yahut, alternatif olarak, ‘GAYRİMENKUL’, ‘ARAÇ’, ‘TELEFON’, ‘SAAT’, ‘MÜCEVHER’ vb şeklinde dilediğiniz gibi
          isimlendirebileceğiniz <u>yeni portföyler</u> oluşturup (toplamda 20 adede kadar portföy oluşturabilirsiniz) bu tür varlıklarınızın değerlerini dilediğiniz para birimi
          (TL,
          USD, EUR, GBP, CHF vs olmak üzere toplam 80 para birimi arasından dilediğiniz gibi seçim yapabilirsiniz) cinsinden ekleyebilirsiniz.
        </p>
      </FAQItem>

      <FAQItem title={"Favori varlıklarım için ‘Takip Listesi’ oluşturabilir miyim?"}>
        <p>
          Varlık Liste (Birim Fiyat) ekranlarınızda, sağ üstteki yıldız ikonuna dokunarak, favori varlıklarınızı sıralamada <u>kalıcı biçimde</u> yukarı taşıyabilirsiniz;
          ekranlarınız daha hızlı açılıyor olacaktır.
        </p>
      </FAQItem>

      <FAQItem title={"Borç portföyü de oluşturabilir miyim?"}>

        <p>paramla'nın temel fonksiyonu, herhangi bir anda sahip olunan varlıklar için portföy değeri & kar/zarar takibi sağlamak ve bu suretle, her finansal okuryazarlık
          seviyesinden
          kullanıcımızı ‘tasarruf’a özendirmektir. Dolayısı ile, ana ilgi alanımız ‘varlık’ olup, ‘borç’ kısmı ilgi alanımıza girmemekte ve ‘-’ işaretle borç / yükümlülük takip
          desteğimiz bulunmamaktadır.</p>

        <p>Bununla birlikte, şu adımları izleyerek, kolayca bir ‘borç portföyü’ oluşturabilirsiniz kendinize:</p>

        <ol>
          <li>Portföy (‘p’) ekranınızda portföy isminizin üzerine dokunup, açılan menüden ‘PORTFÖY EKLE’yi seçin;</li>
          <li>Yeni portföyünüze, borçlarınızı çağrıştıracak bir isim verin;</li>
          <li>‘Portföyler Toplamı’na dahil et’ seçimini kapatıp ‘OLUŞTUR’a dokunun.</li>
        </ol>

      </FAQItem>

      <FAQItem title={"Cihazımı sıfırladım, ne yapmalıyım?"}>
        <p>Her daim ‘anonim’ kalabilin diye, paramla’da herhangi bir kişisel veriniz (ad, soyad, e-posta adresi vb.) sorulmaz ve saklanmaz; kayıt (hesap oluşturma), giriş, şifre
          belirleme gibi süreçler yoktur; verileriniz ve aboneliğiniz, <u>cihaz ID’niz</u> bazında saklanır / takip edilir.</p>

        <p>Cihazınızı sıfırladıysanız, cihaz ID’si de değişmiştir; hatırlıyorsanız, <u>ayrıştırıcı ve spesifik birkaç varlığınızı ve miktarlarını (mümkünse portföy isimlerinizle
          birlikte)</u>
          bize iletebilirseniz lütfen, önceki cihaz ID’nizi tespit edip yedeklerimizden varlıklarınızı tekrar kopyalayabiliriz cihazınıza.
        </p>
      </FAQItem>

      <FAQItem title={"Cihaz değiştirdim, ne yapmalıyım?"}>

        <p>Her daim ‘anonim’ kalabilin diye, paramla’da herhangi bir kişisel veriniz (ad, soyad, e-posta adresi vb.) sorulmaz ve saklanmaz; kayıt (hesap oluşturma), giriş, şifre
          belirleme gibi süreçler yoktur; verileriniz ve aboneliğiniz, <u>cihaz ID’niz</u> bazında saklanır / takip edilir.</p>

        <p>Dolayısı ile,</p>

        <ul>
          <li>Eski cihazınızda ‘Ayarlar / Cihaz Yönetimi / Cihaz Ekle’ yönergelerini dikkatli bir şekilde tatbik ederek (<u>yeni cihazınızda gösterilecek eşleme kodunu eski
            cihazınıza
            girerek, tersi değil</u>) yeni cihazınızı ekleyebilir;
          </li>

          <li>yeni cihazınızda yeni bir abonelik oluşturup eski cihazınızdaki aboneliğinizi sonlandırabilir;</li>

          <li>dilerseniz, <u>eski cihazınızda</u>, ‘Ayarlar / Cihaz Yönetimi / Sıfırla / Tüm portföy geçmişlerim’ menüsü üzerinden, eski cihazınızı sıfırlayabilirsiniz.</li>
        </ul>

        <p style={{fontStyle: "italic"}}>
          Eski cihazınıza erişiminiz yoksa, cihaz ID’sini tespit edebilmemiz için, varsa ve hatırlıyorsanız <u>ayrıştırıcı ve spesifik birkaç varlığınızı ve miktarlarını (mümkünse
          portföy
          isimlerinizle birlikte)</u> bize iletebilirseniz lütfen, veritabanımızda eski cihazınızı tespit etmeye çalışıp varlıklarınızı yeni cihazınıza kopyalayabiliriz.
        </p>
      </FAQItem>

      <FAQItem title={"Cihaz ekleme kodu kabul olmuyor, ne yapmalıyım?"}>

        <p>‘Cihaz Ekle’ kodlarımız sürelidir; aldığınız kodun süresi dolmuş olabilir. Yeniden kod alarak tekrar deneyebilirsiniz lütfen. Yine sorun yaşarsanız, her 2 cihazdan da
          uygulamayı tamamen silip (verileriniz kaybolmaz, merak etmeyin) yeniden indirdikten sonra tekrar deneyebilirsiniz lütfen.</p>
      </FAQItem>

      <FAQItem title={"Neden miktar düzenlemesi yapamıyorum?"}>
        <p>Miktar düzenlemesi yapabilmeniz için, Portföy (‘p’) ekranınızda ‘Portföyler Toplamı’ yerine somut bir portföyünüz seçili olmalıdır; ‘Portföyler Toplamı’ gösterilmekte
          ise, önce üzerine dokunup açılacak listeden somut bir portföyünüzü seçmelisiniz lütfen.</p>
      </FAQItem>

      <FAQItem title={"Vadeli mevduatımı nasıl silebilirim?"}>
        <p>Basitçe ‘Miktar’ alanına 0 (sıfır) yazıp kaydetmeniz yeterlidir.</p>
      </FAQItem>

      <FAQItem title={"Varlık eklediğimde neden tamamı ‘Kar’ olarak görünüyor?"}>

        <p>Bahsettiğiniz gibi bir Kar gösterimimiz yoktur: söz ettiğiniz tutar Kar/Zarar tutarınız değil, Kar/Zarar tutarınızdaki <u>Günlük Değişim</u> (<u>paramla’da kayıtlı bir
          önceki gün
          sonu
          değerine kıyasla</u>) tutarıdır. Sözgelimi, bugün ilk kez miktar girişi yaptığınız bir varlığın dün gün sonu kayıtlı değeri 0 (sıfır)dır.</p>

        <p><u>Bir varlık miktarınızı düzenlediğinizde, artırdığınız yahut eksilttiğiniz tutar, doğal olarak ve sadece o gün özelinde (ertesi günden itibaren normalize olmak üzere)
          Günlük
          Değişim tutar ve oranınıza dahil olur.</u></p>

        <p>Portföy (‘p’) ekranında, Kar/Zarar’a; Kar/Zarar ekranında, Portföy Değeri’ne ilişkin hiçbir gösterimimiz yoktur.</p>
      </FAQItem>

      <FAQItem title={"‘Birim Maliyet’ tutarlarımı girerken neden ‘Para Birimi’ seçemiyorum?"}>

        <p>‘Kar/Zarar (K/Z)’ hesaplamalarında finansal prensip, maliyet para birimi ile <u>genel kabul görmüş satış fiyatı para birimini</u> aynı tutmaktır. Bu birim, ‘BIST’,
          ‘Emtia’,
          ‘Döviz’
          ve ‘Fon’ kategorileri için ‘TL’; ‘Eurobond’, ‘Kripto’ ve ‘ABD Borsaları’ kategorileri için ‘USD’dir. Sözgelimi, ‘Kripto’ varlıklarınız için maliyetinizi ‘TL’ cinsinden
          sorup
          hesaplamalara konu edersek, ilgili varlığınızın K/Z oranını ‘USD’nin ‘TL’ karşısındaki valüasyonu / devalüasyonu ile birleştirerek / karıştırarak raporlamış oluruz ki bu
          durumda salt o varlığınızın kendi başına K/Z oranını görmeniz mümkün olmaz.</p>
      </FAQItem>

      <FAQItem title={"Neden bazı Eurobond’ları bulamıyorum?"}>
        <p>Şu an için ‘ForInvest’in (eski adıyla ‘Foreks’) fiyat döndüğü, <u>Borsa’da işlem gören ve USD cinsinden</u> arz edilmiş Eurobond’ları gösterebiliyoruz sadece.</p>
      </FAQItem>

      <FAQItem title={"Neden bazı kriptoları bulamıyorum?"}>

        <p>
          ‘Kripto’ kategorisini, dünyanın en muteber platformu olan ‘CoinMarketCap’ten (CMC) dinliyoruz. Söz ettiğiniz kriptolar CMC’de yayına girdiğinde, paramla’da da otomatik
          olarak yayına giriyor olacaklardır.
        </p>
      </FAQItem>

      <FAQItem title={"Neden ‘Alış Tarihi’ girişi yok?"}>

        <p>Sadelik ve kullanım kolaylığı adına tarih girişi özelliği düşünmedik paramla’da. Ayrıca, yaptığımız araştırmalarda gördük ki varlığın edinildiği tarih - ki hatta saat ve
          dakika da gerekiyor alım fiyatının tam olarak tespiti için - çoğu zaman hatırlanmıyor yahut yanlış hatırlanabiliyor; o zaman da tarihsel veriler üzerinden yapılan
          değerleme,
          maliyet vb. hesaplamalar yapılamıyor yahut hatalı olabiliyor. <u>Alış tarihi yerine ‘Birim Maliyet’ soruyor ve tutuyoruz</u> çünkü gördük ki Birim Maliyet’ler daha tam ve
          doğru
          hatırlanıyor.</p>


        <p>Miktarlarınızı düzenlerken peyderpey gireceğiniz Birim Maliyet tutarları üzerinden, tarafımızca ve otomatik olarak ‘Ağırlıklı Ortalama Maliyet’ tutarlarınız hesaplanır /
          güncellenir ve Kar/Zarar tutar ve oran hesaplamalarınıza konu edilir.</p>

      </FAQItem>

      <FAQItem title={"Satış yaptığımda neden ‘Satış Fiyatı’ sorulmuyor ve varlık miktarlarım otomatik olarak düzenlenmiyor?"}>

        <p>Öncelikle, paramla'nın misyonu, tasarrufu teşvik etmek ve finansal okuryazarlığın gelişimine katkıda bulunmak suretiyle, ‘finansal özgürlük’ kazanımını
          kolaylaştırmaktır;
          yaşam boyu elde edilen kar/zarar realizasyonlarının raporlanması değil doğrusu.</p>

        <p>Diğer yandan, her varlık eksiltme işlemi satış kaynaklı olmayabildiğinden, eksiltme esnasında satış fiyatı sorup otomatik bir hesaplama / aktarım yapmamayı tercih ettik.
          Bir
          varlık sattığınızda, ilgili varlığın miktarını eksilttikten sonra, satış sonucu elde ettiğiniz likit varlığın miktarını (TL, döviz vb.) manuel olarak artırabilirsiniz.
          Örneğin, satış sonucu TL elde ettiğiniz durumlar için, bir kısmını ‘Kasa’ yahut ‘Vadeli / Vadesiz Mevduat’, kar kısmını ‘Diğer’ alt kategorisine girebilirsiniz. Yahut,
          kar
          realizasyonlarınızı oluşturacağınız ve dilediğiniz gibi isimlendireceğiniz, tamamen ayrı bir portföyde de takip edebilirsiniz.</p>

      </FAQItem>

      <FAQItem title={"Verilerimizin yedekleri alınıyor mu?"}>
        <p>
          Elbette, verilerinizin yedekleri her gün 18:00’de tarafımızca otomatik olarak alınmakta ve son 14 gün için saklanmaktadır.
        </p>
      </FAQItem>

      <FAQItem title={"Uygulamanın bilgisayar yahut web versiyonu var mı?"}>
        <p>
          Hayır, şu an için sadece mobil market (Google Play Store | Apple App Store | Huawei App Gallery) versiyonlarımızla yayındayız.
        </p>
      </FAQItem>

      <FAQItem title={"Uygulamaya nasıl puan verip yorum yazabilirim?"}>

        <p>
          Aşağıdaki bağlantılar üzerinden uygulamaya kolayca puan verip yorum yazabilirsiniz:
        </p>

        <p style={{display: "flex", justifyContent: "space-between"}}>
          <a style={{textDecoration: "underline"}} href={"https://paramla.com/google"}>Google Play Store</a>
          <span>|</span>
          <a style={{textDecoration: "underline"}} href={"https://apps.apple.com/us/app/paramla/id1552559235?action=write-review"}>Apple App Store</a>
          <span>|</span>
          <a style={{textDecoration: "underline"}} href={"https://apps.apple.com/us/app/paramla/id1552559235?action=write-review"}>Huawei App Gallery</a>
        </p>
      </FAQItem>

      <FAQItem title={"Uygulama ilk 30 gün sonrası neden ücretli?"}>
        <p>
          2 yıl ücretsiz sunduk uygulamayı; reklamla ücretsiz devam ettirmeyi de denedik ancak gördük ki reklam gelirleri aylık masraflarımızın otuzda birini dahi karşılamıyor.
        </p>
        <p>
          Dolayısı ile, anlayışınızı rica eder (<u>Ücretli Abonelik dahilinde reklam gösterimimiz yoktur</u>), keyifli kullanımlar ve bol kazançlı birikimler dileriz!
        </p>
      </FAQItem>

      <FAQItem title={"Ücretli aboneliğin farkı nedir? İlk 30 gün sonrasında ücretli abone olmazsam ne olur?"}>
        <ul>
          <li>
            30 gün ücretsiz deneme süresi sonunda abone olmazsanız, Portföy (‘p’), Kar/Zarar ve En Çok Kazandıranlar ekranlarınız gösterilmez; Varlık Liste (Birim Fiyat)
            ekranlarını
            görüntülemeye devam edebilirsiniz.
          </li>
          <li>
            Ücretli Abonelik dahilinde reklam gösterimimiz yoktur.
          </li>
        </ul>
      </FAQItem>


      <FAQItem title={"Abonelik ücretini 3 aylık, 6 aylık veya yıllık olarak ödeyebilir miyim?"}>

        <p>
          Şu an için Store hesabınızda tanımlı ödeme yönteminizden (Kredi Kartı, Operatör Faturası vb.) her ay otomatik olarak tahsil edilmek üzere, <u>sadece aylık
          abonelik</u> seçeneğimiz bulunmaktadır.
        </p>
      </FAQItem>

      <FAQItem title={"Abonelik ücretini EFT/Havale ile ödeyebilir miyim?"}>

        <p>
          Şu an için <u>Store hesabınızda tanımlı ödeme yönteminizden (Kredi Kartı, Operatör Faturası vb.)</u> her ay otomatik olarak tahsil edilmek üzere, sadece aylık abonelik
          seçeneğimiz
          bulunmaktadır.
        </p>
      </FAQItem>


      <FAQItem title={"Abonelik ücretini ödeyemiyorum, ne yapmalıyım?"}>
        <p>Ödeme / abonelik süreçlerinde biz hiç yokuz; bu kısım tamamen Store’ların standart akışları üzerinden yürümekte.</p>
        <ul>
          <li>Store hesabınızda tanımlı olan ödeme yönteminizi silip baştan yeniden tanımlamayı;</li>
        </ul>
        <p>ve/veya</p>
        <ul>
          <li>
            uygulamayı tamamen silip tekrar indirdikten sonra (verileriniz kaybolmaz, merak etmeyin) abone olmayı yahut aboneliğinizi geri yüklemeyi
          </li>
        </ul>
        <p>
          deneyebilirsiniz lütfen.
        </p>
        <p>
          Store’unuzun Destek hattına da danışabilirsiniz belki.
        </p>
      </FAQItem>


      <FAQItem title={"Aboneliğimi nasıl sonlandırabilirim?"}>

        <p>‘Ayarlar / Abonelik / ABONELİĞİ SONLANDIR’ adımlarını izleyerek, aboneliğinizi dilediğiniz an ve kolayca sonlandırabilirsiniz; bir sonraki ay için aboneliğiniz
          yenilenmeyecek
          ve yeniden abone olana dek herhangi bir ilave ücret tahsilatı yapılmayacaktır (Not: aboneliğinizi sonlandırsanız dahi, devam etmekte olan abonelik döneminiz bitene dek
          uygulamayı kullanmaya devam edebilirsiniz).</p>

        <p>Dilerseniz, aboneliğinizi Store hesabınız üzerinden ‘Devam Eden Abonelikler’inizi kontrol ederek de sonlandırabilirsiniz.</p>

      </FAQItem>
      <FAQItem title={"Hesabımı nasıl silebilirim?"}>
        <ul>
          <li>
            Her daim ‘anonim’ kalabilin diye, paramla’da herhangi bir kişisel veriniz (ad, soyad, e-posta adresi vb.) sorulmaz ve saklanmaz; kayıt (hesap oluşturma), giriş, şifre
            belirleme gibi süreçler yoktur. Dolayısı ile, hesabınızı kapatma gibi bir süreç de yoktur; basitçe uygulamayı silmeniz yeterlidir. Bununla birlikte, dilerseniz,
            ‘Ayarlar
            /
            Cihaz Yönetimi / Sıfırla / Tüm portföy geçmişlerim’ adımlarını izleyerek, uygulamayı silmeden önce varlıklarınızı sıfırlayabilirsiniz.
          </li>
          <li>
            Aboneliğiniz yoksa, herhangi bir ücret tahsilatı yapılmayacaktır; abone iseniz, ‘Ayarlar / Abonelik / ABONELİĞİ SONLANDIR’ adımlarını izleyerek, aboneliğinizi
            dilediğiniz
            an
            ve kolayca sonlandırabilirsiniz; böyle bir durumda, bir sonraki ay için aboneliğiniz yenilenmeyecek ve yeniden abone olana dek herhangi bir ilave ücret tahsilatı
            yapılmayacaktır (Not: aboneliğinizi sonlandırsanız dahi, devam etmekte olan abonelik döneminiz bitene dek uygulamayı kullanmaya devam edebilirsiniz).
          </li>
        </ul>
      </FAQItem>
    </div>
  </LayoutPlain>
}

export default FAQTRPage;
